<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="100">
      <Alert type="warning" v-if="form.type === '2'">请确认是否需要调整检查项，如需要请<a href="javascript:" @click="checkItemFlag=true"> 点击此处 </a>！</Alert>
      <FormItem label="项目/企业" prop="projectName" style="width: 100%">
        <Input v-model="form.projectName" disabled placeholder="项目/企业"></Input>
      </FormItem>
      <FormItem label="检查专家" prop="expertName" style="width: 100%">
        <Input v-model="form.expertName" disabled placeholder="检查专家"></Input>
      </FormItem>
      <!-- <FormItem label="巡检日期" prop="serveTime">
        <ys-date-picker type="datetime" v-model="form.serveTime" style="width: 100%;" placeholder="巡检日期"></ys-date-picker>
      </FormItem> -->
      <FormItem label="备注" prop="riskRemarks" style="width: 100%">
        <Input v-model="form.riskRemarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="输入备注"></Input>
      </FormItem>
    </Form>
    <h1 class="h1Title">风险记录</h1>
    <ProblemsList @on-ok="getData" :detailInner="true" :footer="false" :multiColumn="false" :tableHeight="height-450" ref="problems" :mid="checkID" v-if="checkID"></ProblemsList>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id ? '/amend/Edit' : '/amend/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="checkItemFlag" :width="600" title="调整检查项">
      <categoryEdit :mid="detail.id"></categoryEdit>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit';
import {checkInfo} from "@/api/check";
import {amendInfo} from "@/api/amend";
import ProblemsList from "@/views/rectifications/edit/problem";
import categoryEdit from "@/views/task/categoryEdit";

export default {
  name: 'rectificationsEdit',
  mixins: [edit],
  components: {
    ProblemsList,
    categoryEdit
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填改建议书标题', trigger: 'blur'}
        ],
        deadline: [
          {type: 'string', required: true, message: '整改截止日不能为空', trigger: 'change'}
        ],
      },
      amendInfo: {},
      detail: {},
      checkItemFlag: false,
      form: {
        iname: '',
        id: '',
        expertName: '',
        checkId: '',
        type: '',
        projectId: '',
        projectName: '',
        serveTime: new Date(),
        deadline: new Date(new Date().getTime() + 3600 * 24 * 1000 * 7),
        commentList: [],
        riskRemarks: '',
      },
    }
  },
  computed: {
    checkID() {
      return this.parentID ? this.parentID : this.form.checkId
    }
  },
  mounted() {
    this.form.deadline = this.$formatDate(new Date(new Date().getTime() + 3600 * 24 * 1000 * 7))
    if (this.mid) {
      amendInfo({id: this.mid}).then((res) => {
        this.amendInfo = res.data
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.getSpecialInfo(this.form.checkId);
      })
    } else {
      this.getSpecialInfo(this.parentID);
    }
  },
  methods: {
    getData(data) {
      this.form.commentList = (data.data.list || []).map(item => item.id);
    },
    getSpecialInfo(id) {
      checkInfo({id: id}).then((res) => {
        this.detail = res.data
        this.form.projectId = this.detail.projectId;
        this.form.projectName = this.detail.project ? this.detail.project.iname : '';
        this.form.iname = this.detail.iname + '的整改单';
        this.form.checkId = this.detail.id;
        this.form.type = this.detail.type;
        this.form.expertName = this.detail.expertList.map((item) => item.iname).join();
      })
    },

  }
}
</script>
