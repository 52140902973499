<template>
  <div>
    <Row>
      <Col :span="orgNames.includes(userOrgName)?16:24">
        <Form
            ref="form"
            class="editForm twoPartsEditForm"
            :model="form"
            :rules="ruleValidate"
            inline
            :style="{height: `${height - 290}px`, overflow: 'auto'}"
            :label-width="110"
            style="padding-bottom: 0">
          <FormItem label="项目名称" prop="iname">
            <Input v-model="form.iname" placeholder="项目名称"></Input>
          </FormItem>
          <FormItem label="施工许可证号" prop="licenseNum">
            <Input v-model="form.licenseNum" placeholder="施工许可证号" search enter-button="临时生成" @on-search="autoGenerate"></Input>
          </FormItem>
          <FormItem label="安监号" prop="code">
            <Input v-model="form.code" placeholder="安监号"></Input>
          </FormItem>
          <FormItem label="工程类型" prop="category">
            <Cascader :data="baseData['项目类型']" v-model="form.categoryList"/>
          </FormItem>
          <FormItem label="项目阶段" prop="status">
            <Select v-model="form.status" transfer clearable placeholder="项目阶段">
              <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="形象进度" prop="progress">
            <Select v-model="form.progress" transfer clearable placeholder="形象进度">
              <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <div style="width: 100%;">
            <FormItem label="所在地区" prop="area" style="width: 30%;">
              <ys-region changeOnSelect type="all" :mustChoiceDepth="form.oType === 4 ? 4 : -1" v-model="form.area" @on-change="getArea" placeholder="所在地区"/>
            </FormItem>
            <FormItem label="" :labelWidth="0" prop="address" style="width: 70%">
              <Input v-model="form.address" placeholder="项目地址"></Input>
            </FormItem>
            <FormItem label="经纬度" prop="lngLat" style="width: 80%">
              <Input v-model="form.lngLat" @on-change="lngLatChange" placeholder="经纬度"></Input>
            </FormItem>
            <FormItem :labelWidth="0" style="width: 20%">
              <Button type="primary" long @click="lnglatFlag=true">手动选择经纬度</Button>
            </FormItem>
          </div>
          <FormItem label="工程概况" prop="overview" style="width: 100%" v-if="checkId>0">
            <Input v-model="projectAddition.overview" type="textarea" maxlength="2000" show-word-limit style="width: 100%" :autosize="{minRows: 5, maxRows: 10}" placeholder="工程概况"></Input>
          </FormItem>
          <FormItem label="备注" prop="description" style="width: 100%">
            <Input v-model="form.description" type="textarea" maxlength="2000" show-word-limit style="width: 100%" :autosize="{minRows: 5, maxRows: 10}" placeholder="备注"></Input>
          </FormItem>
          <FormItem label="实际开工日期" prop="startReal">
            <ys-date-picker v-model="form.startReal" style="width: 100%;" placeholder="实际开工日期"></ys-date-picker>
          </FormItem>
          <FormItem label="实际完工日期" prop="endReal">
            <ys-date-picker v-model="form.endReal" style="width: 100%;" placeholder="实际完工日期"></ys-date-picker>
          </FormItem>
          <FormItem label="项目负责人" prop="principalName">
            <Input v-model="form.principalName" placeholder="项目负责人"></Input>
          </FormItem>
          <FormItem label="负责人电话" prop="principalPhone">
            <Input v-model="form.principalPhone" placeholder="负责人电话"></Input>
          </FormItem>
          <FormItem label="安责险联络员姓名" prop="safetyOfficerName">
            <Input v-model="form.safetyOfficerName" placeholder="安责险联络员姓名"></Input>
          </FormItem>
          <FormItem label="安责险联络员电话" prop="safetyOfficerPhone">
            <Input v-model="form.safetyOfficerPhone" placeholder="安责险联络员电话"></Input>
          </FormItem>
          <FormItem label="合同造价" prop="contractPrice">
            <Input type="number" v-model="form.contractPrice" placeholder="合同造价"><span slot="append">元</span></Input>
          </FormItem>
          <FormItem label="工程造价" prop="amount">
            <Input type="number" v-model="form.amount" placeholder="工程造价"><span slot="append">元</span></Input>
          </FormItem>
          <FormItem label="总面积" prop="buildArea">
            <Input type="number" v-model="form.buildArea" placeholder="总面积"><span slot="append">㎡</span></Input>
          </FormItem>
          <FormItem label="总长度" prop="buildLength">
            <Input type="number" v-model="form.buildLength" placeholder="总长度"><span slot="append">米</span></Input>
          </FormItem>
          <FormItem label="最高层数" prop="floorHeight">
            <Input type="number" v-model="form.floorHeight" placeholder="最高楼层"><span slot="append">层</span></Input>
          </FormItem>
          <FormItem label="高度" prop="buildHeight">
            <Input type="number" v-model="form.buildHeight" placeholder="高度"><span slot="append">米</span></Input>
          </FormItem>
          <FormItem label="施工许可证照片" prop="licenseFileList" style="width: 100%;text-align: center">
            <ysUpload
                @on-success="bLFile(arguments,'licenseFileList')"
                :headers="{token:token}"
                type="halfList"
                :defaultList.sync="licenseFileList"
                action="/web/resource/UploadFile"
            ></ysUpload>
          </FormItem>
          <FormItem label="平面图" prop="floorViewFileList" style="width: 100%;text-align: center">
            <ysUpload
                @on-success="bLFile(arguments,'floorViewFileList')"
                :headers="{token:token}"
                type="halfList"
                :defaultList.sync="floorViewFileList"
                action="/web/resource/UploadFile"
            ></ysUpload>
          </FormItem>
          <FormItem label="鸟瞰图" prop="aerialViewFileList" style="width: 100%;text-align: center">
            <ysUpload
                @on-success="bLFile(arguments,'aerialViewFileList')"
                :headers="{token:token}"
                type="halfList"
                :defaultList.sync="aerialViewFileList"
                action="/web/resource/UploadFile"
            ></ysUpload>
          </FormItem>
        </Form>
      </Col>
      <Col :span="8" style="padding-left: 10px;" v-if="orgNames.includes(userOrgName)">
        <h1 class="h1Title">重大危险源</h1>
        <div id="right" :style="{height: `${height - 340}px`, overflow: 'auto'}">
          <Tree show-checkbox ref="tree" check-directly :data="treeData"></Tree>
        </div>
      </Col>
    </Row>
    <ys-modal v-model="lnglatFlag" :width="1220" title="手动选择经纬度">
      <iframes></iframes>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {projectInfo} from "@/api/projects";
import iframes from "@/views/projects/iframes.vue";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {iframes},
  data() {
    return {
      dangerData: [
        {title: '安全管理', expand: true, children: [{title: '无安全技术措施'}, {title: '违章指挥'}, {title: '未教育'}, {title: '交底'}, {title: '未持证上岗'}, {title: '安全警示标志不全'}]},
        {title: '物体打击'},
        {title: '各类触电事故'},
        {title: '火灾', expand: true, children: [{title: '焊接作业'}, {title: '消防器材'}, {title: '易燃材料'}, {title: '危险品'}]},
        {title: '中毒（化学品、危险品使用及存放）'},
        {title: '起重伤害'},
        {title: '爆炸、压力容器爆炸等'},
        {title: '车辆伤害'},
        {title: '高处坠落', expand: true, children: [{title: '脚手架'}, {title: '操作平台'}, {title: '临边洞口'}, {title: '悬空作业'}, {title: '登高作业'}]},
        {title: '机械伤害'},
        {title: '溺水、溺亡'},
        {title: '各类坍塌', expand: true, children: [{title: '脚手架'}, {title: '模板支架'}, {title: '基坑'}, {title: '操作平台'}, {title: '卸料平台'}, {title: '塔吊、升降机'}]},
        {title: '城市交通安全、夜间无警示标志等、未设置导行标志', expand: true},
        {title: '窒息（受限空间作业）'},
      ],
      ruleValidate: {
        code: [
          {required: true, message: '请填安监号', trigger: 'blur'}
        ],
        iname: [
          {required: true, message: '请填项目名称', trigger: 'blur'}
        ],
        licenseNum: [
          {required: true, message: '请填写施工许可证号！', trigger: 'blur'}
        ],
        area: [
          {required: true, message: '请选择所在区域', trigger: 'change'}
        ],
      },
      treeData: [],
      lnglatFlag: false,
      licenseFileList: [],
      floorViewFileList: [],
      aerialViewFileList: [],
      projectAddition: {overview: '', categoryList: [], summary: '',dangerTreeData:[]},
      form: {
        iname: '',
        orgList: [],
        licenseNum: '',
        industryType: '1',
        tag: 1,
        principalName: '',
        principalPhone: '',
        safetyOfficerName: '',
        safetyOfficerPhone: '',
        category: '99',
        subCategory: '',
        status: '',
        progress: '',
        startPlan: '',
        code: '',
        startReal: '',
        endPlan: '',
        endReal: '',
        id: '',
        lng: '',
        lat: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        contractPrice: '',
        amount: '',
        buildLength: '',
        buildArea: '',
        buildHeight: '',
        floorHeight: '',
        area: '',
        description: '',
        categoryList: [],
        licenseFileList: [],
        floorViewFileList: [],
        aerialViewFileList: []
      },
    }
  },
  props: {
    orgNames: {type: Array, default: () => []},
    checkId: {type: Number, default: null}
  },
  computed: {
    userOrgName() {
      const orgs = (this.userInfo.orgs || []).find(item => item.oType === 3 && this.orgNames.includes(item.iname))
      return orgs?.iname
    },
    categoryList() {
      const category = this.form.category
      let data = this.baseData['项目类型'].find(item => item.value === category)
      return data?.children || [];
    },
  },
  mounted() {
    if (this.mid) {
      projectInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null;
        })
        this.licenseFileList = res.data.licenseFileList || [];
        this.floorViewFileList = res.data.floorViewFileList || [];
        this.aerialViewFileList = res.data.aerialViewFileList || [];
        this.form.licenseFileList = this.licenseFileList.map(item => item.id);
        this.form.floorViewFileList = this.floorViewFileList.map(item => item.id);
        this.form.aerialViewFileList = this.aerialViewFileList.map(item => item.id);
        this.form.area = res.data.countyCode || res.data.cityCode || res.data.provinceCode;
        this.form.lngLat = this.form.lat && this.form.lng ? (this.form.lat + ',' + this.form.lng) : '';
        this.form.categoryList = [];
        if (this.form.category) this.form.categoryList.push(this.form.category)
        if (this.form.subCategory) this.form.categoryList.push(this.form.subCategory);
      })
    }
    if (this.checkId > 0) {
      this.$get('/check/addition/GetFieldByCheckId', {checkId: this.checkId, field: 'projectAddition'}).then((res) => {
        this.projectAddition = res.data || {overview: '', categoryList: [], summary: '',dangerTreeData:[],};
        this.treeData = this.projectAddition?.dangerTreeData?.length > 0 ? this.projectAddition.dangerTreeData : this.dangerData;
      })
    }
  },
  methods: {
    lngLatChange() {
      this.form.lng = this.form.lngLat.split(',')[1] || ''
      this.form.lat = this.form.lngLat.split(',')[0] || ''
    },
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
      if (data.length === 0) this.form.area = '';
    },
    bLFile(data, key) {
      this.form[key] = data[0].map(item => item.id);
    },
    autoGenerate() {
      this.form.licenseNum = `XKZ${new Date().getTime()}`
    },
    getLocation(data) {
      this.form.lat = data.location ? data.location.lat : '';
      this.form.lng = data.location ? data.location.lng : '';
      this.form.address = data.address || '';
      this.form.area = data.code?.countyCode || data.code?.cityCode || data.code?.provinceCode || '';
    },
    save() {

      if (this.form.endReal && this.form.startReal && new Date(this.form.endReal).getTime() < new Date(this.form.startReal).getTime()) {
        this.$Message.error('完工日期不得小于开工日期！');
        return
      }
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.categoryList.length > 1) this.form.subCategory = this.form.categoryList[1]
          if (this.form.categoryList.length > 0) this.form.category = this.form.categoryList[0]
          this.$post(this.form.id ? '/projects/pcEdit' : '/projects/Add', this.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.close();
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              if (this.orgNames.includes(this.userOrgName)) {
                const allData = this.$refs['tree'].compileFlatState();
                this.projectAddition.dangerTreeData = [];

                this.treeData.forEach(item => {
                  const targetIdx = allData.findIndex(item2 => item2.node.title === item.title);
                  const data = targetIdx >= 0 ? allData[targetIdx].node : {expand: item.expand, checked: false, indeterminate: false, title: item.title, children: item.children || []}
                  this.projectAddition.dangerTreeData.push(data);
                })
                if(!['01','02'].includes(this.form.category)){
                   this.projectAddition.dangerList=[];
                }
                this.$post('/check/addition/AddOrEdit', {data: this.projectAddition, field: 'projectAddition', checkId: this.checkId})
                this.$emit('on-ok', res.data);
              } else {
                this.$emit('on-ok', res.data);
              }
            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>
