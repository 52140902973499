<script>
import edit from '@/mixins/edit'
import {getFile} from "@/api/common.js";
import {del} from "ys-admin";

export default {
  name: 'reportEditTask',
  mixins: [edit],
  props: {
    checkDetail: {type: Object, default: () => ({})},
  },
  data() {
    return {
      fileList: {name: '现场服务专家与项目工地名称合影', resourceType: 2, type: 201, maxCount: 10, list: []},
      defaultFileList: [],
      defaultFile: [],
    }
  },
  async mounted() {
    await this.getFiles();
  },
  methods: {
    async getFiles() {
      try {
        const res = await getFile({
          resourceId: this.checkDetail.id,
          resourceType: this.fileList.resourceType,
          type: this.fileList.type
        });
        if (res.code === 200) {
          this.defaultFileList = this.defaultFile = res.data.list || [];
        }
      } catch (error) {
        console.error("获取文件失败", error);
      }
    },
    async bLFile(data) {
      this.defaultFile = [];
      data.forEach((item) => {
        let [name, suffix] = item.fileName.split('.');
        item.resourceId = this.checkDetail.id;
        item.resourceType = this.fileList.resourceType;
        item.type = this.fileList.type;
        item.fileName = name
        item.suffix = suffix;
        this.defaultFile.push(item);
      });
      const newFileIds = data.map(item => item.id);
      // 检查并删除不在新文件列表中的文件
      for (const item of this.defaultFileList.map(item => item.id)) {
        if (!newFileIds.includes(item)) {
          await del('/resource/file/Remove', {ids: [item]});
        }
      }
      this.fileUpload();
    },
    async fileUpload() {
      try {
        await Promise.all(this.defaultFile.map(async (item) => {
          item.fileName = item.fileName + '.' + item.suffix;
          delete item.response;
          await this.$post('/resource/file/Edit', item);
        }));
      } catch (error) {
        console.error('文件上传失败:', error);
      }
      this.getFiles();
    },
    validateUploads() {
      return this.defaultFile.length >= 6;
    },
  }
}
</script>

<template>
  <div class="taskBox">
    <h1 class="h1Title">现场服务专家与项目工地名称合影</h1>
    <ysUpload 
      @on-success="bLFile($event)"
      :headers="{token:token}"
      ref="upload"
      type="halfList"
      :limit="fileList.maxCount"
      :defaultList.sync="defaultFile"
      :format="['jpg','jpeg','png','gif']"
      action="/web/resource/UploadFile"></ysUpload>
  </div>
</template>

<style scoped lang="less">
.taskBox {
  background-color: var(--el-bg-color);
  height: calc(100vh - 300px);
  overflow-y: auto;
}
</style>