<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :tableHeight="resultHeight + 100"
        :header="false"
        :footer="false"
        :multiColumn="false"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :headerColumns="headerColumns"
        :tableLoading="tableLoading"
        :tableData="tableDataList"
    >
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {all, get, post} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList],
  components: {},
  props: {
    checkDetail: {
      type: Object, default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 150, title: '级别', key: 'standardTypeName', align: 'center'},
        {minWidth: 150, title: '类别', key: 'pname', align: 'center'},
        {width: 400, title: '工程范围', key: 'name', align: 'left'},
        {
          minWidth: 150, title: '结果', key: 'result', align: 'center', render: (h, params) => {
            return h('RadioGroup', {
                  props: {
                    value: this.baseData['有无'].find(item => item.value === params.row.result)?.name || '否'
                  },
                  on: {
                    'on-change': (value) => {
                      this.standardCheckEdit(params.row, value)
                    }
                  }
                },
                this.baseData['有无'].map(item => {
                  return h('Radio', {props: {disabled: this.checkDetail.status !== 5, label: item.name}})
                })
            )
          }
        },
      ],
      modShow: false,
      params: {
        checkId: '',
      },
      tableLoading: false,
      tableDataList: [],
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: []
        },
      ]
    },
  },
  mounted() {
    this.params.checkId = this.checkDetail.id
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.tableLoading = true
      this.tableDataList = []
      await all([
        get('/standardCheck/List', {standTypeId: 1, ...this.params, limit: -1, isTop: false, noArea: true}),
        get('/standardCheck/List', {standTypeId: 2, ...this.params, limit: -1, isTop: false, noArea: true})
      ]).then(res => {
        const greatDanger = (res[0]?.data || []).map(item => {
          (item.children || []).sort((a, b) => b.index - a.index).forEach(ite => ite.pname = item.name)
          return item.children
        }).flat(Infinity)
        const superGreatDanger = (res[1]?.data || []).map(item => {
          (item.children || []).sort((a, b) => b.index - a.index).forEach(ite => ite.pname = item.name)
          return item.children
        }).flat(Infinity)
        this.tableDataList = [...greatDanger, ...superGreatDanger]
      })
      this.tableLoading = false
    },
    async standardCheckEdit(row, value) {
      this.tableLoading = true
      const result = this.baseData['有无'].find(item => item.name === value)?.value || ''
      await post('/standardCheck/Edit', {id: row.pid, projectId: this.checkDetail.projectId , checkId: row.checkId, result: result, standardCheckId: row.id, random: new Date().getTime()}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: "提示！",
            desc: '操作成功！'
          });
        }
      })
      this.tableLoading = false
      // this.getDataList();
    }
  }

}
</script>
