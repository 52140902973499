<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/orgProject/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="参建单位类型" prop="type">
          <Select v-model="params.type" transfer clearable placeholder="参建单位类型" style="width: 100px;">
            <Option v-for="(item,index) in baseData['参建单位类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增参建单位</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="500" title="新增/修改参建单位">
      <EditCorp :midData="tableSelectItem" :parentID="params.projectId" :oType="4" :source="0" @on-ok="getList"></EditCorp>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'EditCorp': () => import('@/views/projects/detail/corp/orgEdit.vue'),
  },
  props: {
    projectId: {
      type: [String, Number],
      default: ''
    },
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/orgProject/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  data() {
    return {
      params: {
        oType: 4,
        type: '',
        projectId: ''
      },
      bindOrg: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 250, title: '单位名称', key: 'iname', align: 'left'},
        {width: 90, title: '单位类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '参建单位类型'}},
        {width: 180, title: '统一社会信用代码', key: 'societyCode', align: 'left'},
        {width: 80, title: '联系人', key: 'contacts', align: 'left'},
        {minWidth: 150, title: '联系人电话', key: 'phone', align: 'left', renderConfig: {type: 'phoneDesensitise'}},
      ]
    }
  },
  mounted() {
    this.params.projectId = this.projectId ? this.projectId : this.mid || '';
    this.getList()
  },
  methods: {

  }
}
</script>
