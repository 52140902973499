<template>
  <div style="position:relative;">
    <Steps :current="step" align-center :key="String(stepList)">
      <Step :title="item.title" :key="item.title" v-for="item in stepList"></Step>
    </Steps>
    <div class="editForm" v-if="!loading">
      <template v-for="(item,index) in stepList">
        <template v-if="step===index">
          <template v-if="!!item.components">
            <projects
                :orgNames="orgNames"
                :ref="item.ref"
                :checkId="checkId"
                :mid="projectId"
                @on-ok="projectOk"
                v-if="step===0"></projects>
            <component
                :ref="item.ref"
                :is="item.components"
                :detailInner="true"
                :mid="checkId"
                :projectId="projectId"
                :midData="[check]"
                :tableHeight="height-410"
                :checkId="checkId"
                :checkDetail="check"
                @dangerUploadSuccess="handleDangerUploadSuccess"
                v-else></component>
          </template>
          <template v-else>
            <Row>
              <Col :span="24">
                <div v-if="check.type<=2">
                  <Alert type="warning" v-if="check.type === '2'">请确认是否需要调整检查项，如需要请<a href="javascript:" @click="checkItemFlag=true"> 点击此处 </a>！</Alert>
                </div>
                <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100" style="height: calc(100vh - 330px);">
                  <FormItem label="项目风险综合总体评价" prop="summary" v-if="check.type<=2&&orgNames.includes(userOrgName)">
                    <Input type="textarea" v-model="projectAddition.summary" :autosize="{minRows: 5,maxRows: 10}" placeholder="项目风险综合总体评价"></Input>
                  </FormItem>
                  <FormItem label="项目风险分项评价" prop="items" v-if="check.type<=2&&orgNames.includes(userOrgName)">
                    <Alert type="warning">数据有更新？可以<a href="javascript:;" @click="refresh">点击此处更新</a> ，已填信息将被清空。</Alert>
                    <table cellspacing="1" width="100%" cellpadding="0" border="0" class="baseTable">
                      <col style="width: 8%; text-align: center;"/>
                      <col style=""/>
                      <col style="width: 8%; text-align: center;"/>
                      <col style=""/>
                      <col style=""/>
                      <tr>
                        <th>分项工程</th>
                        <th>涉及施工类型</th>
                        <th>风险隐患数量</th>
                        <th>重要风险提示</th>
                        <th>现阶段风险评估</th>
                      </tr>
                      <tr v-for="item in projectAddition.categoryList">
                        <td align="center"><Input v-model="item.name"/></td>
                        <td align="center"><Input v-model="item.type"/></td>
                        <td align="center">
                          <InputNumber :step="1" :min="0" :precision="0" v-model="item.num"/>
                        </td>
                        <td align="center"><Input v-model="item.tips"/></td>
                        <td align="center"><Input v-model="item.desc"/></td>
                      </tr>
                    </table>
                  </FormItem>
                  <FormItem label="结论" prop="desc" v-else>
                    <Input type="textarea" v-model="form.desc" :autosize="{minRows: 19,maxRows: 19}" placeholder="输入结论"></Input>
                    <Tag @click.native="checkDesc(0)" style="cursor: pointer" v-if="check.type<=2">模板一</Tag>
                    <Tag @click.native="checkDesc(1)" style="cursor: pointer" v-if="check.type<=2&&orgNames.includes(userOrgName)">模板二</Tag>
                  </FormItem>
                  <template v-if="form.type===7">
                    <FormItem label="安全评分" prop="score">
                      <InputNumber :min="0" style="width: 100%" v-model="form.score" placeholder="安全评分"></InputNumber>
                    </FormItem>
                    <FormItem label="安全等级" prop="result">
                      <Select v-model="form.result" placeholder="安全等级">
                        <Option v-for="(item,index) in baseData['安全生产标准化等级']" :value="item.value" :key="index">{{ item.name }}</Option>
                      </Select>
                    </FormItem>
                  </template>
                  <FormItem label="整改建议" prop="suggestion" v-if="check.type<=2||check.type===9">
                    <Row>
                      <Col span="22">
                        <Input type="textarea" style="width: 100%;word-break: break-all;" v-model="form.suggestion" :autosize="{minRows: 2,maxRows: 6}" placeholder="输入整改建议"></Input>
                      </Col>
                      <Col span="2">
                        <Button type="primary" style="width: 100%;height: 100%;" @click="quickInfoShow = true">选择</Button>
                      </Col>
                    </Row>
                  </FormItem>
                  <FormItem label="报告附件" prop="fileList">
                    <ys-upload :action="config.baseURL+'/resource/UploadFile'" :defaultList="detail.fileList||[]" @on-success="bLFile" :headers="{token:token}" type="halfList"></ys-upload>
                  </FormItem>
                </Form>
              </Col>
              <Col :span="0"></Col>
            </Row>
          </template>
        </template>
      </template>
    </div>
    <div class="modalEditBtnArea">
      <Button title="上一步" :loading="submitLoading" type="primary" @click="step--" v-if="step>0">上一步</Button>
      <Button title="下一步" :loading="submitLoading" type="primary" @click="stepPlus" v-if="step<stepList.length-1">下一步</Button>
      <Button title="保存" :loading="submitLoading" type="primary" @click="submit()" v-if="step===stepList.length-1">仅保存</Button>
      <template v-if="step===stepList.length-1">
        <Poptip
            placement="top-start"
            trigger="hover"
            v-if="form.fileList.length===0">
          <template #content>
            可能原因：
            <!--          <p>1、您不是组长;</p>-->
            <!--          <p>2、您尚未签退;</p>-->
            <p>1、未上传报告附件。</p>
          </template>
          <Button
              title="保存并提交"
              :loading="submitLoading"
              type="primary"
              v-if="step===stepList.length-1"
              @click="submit(true)"
              :disabled="form.fileList.length===0">保存并提交
          </Button>
        </Poptip>
        <Button
            title="保存并提交"
            :loading="submitLoading"
            type="primary"
            v-else
            @click="submit(true)"
            :disabled="form.fileList.length===0">保存并提交
        </Button>
      </template>
      <Button title="关闭" @click="close()">关闭</Button>
    </div>
    <ys-modal v-model="amendFlag" :width="1000" title="生成整改单">
      <Edit @on-ok="getDetail" :parentID="checkId" :taskType="check.type"></Edit>
    </ys-modal>
    <ys-modal v-model="checkItemFlag" :width="600" title="调整检查项">
      <categoryEdit :mid="checkId"></categoryEdit>
    </ys-modal>
    <ys-modal v-model="quickInfoShow" :width="1000" :title="'选择整改建议'">
      <QuickInfo :orgId="serveOrgId" :type="2" :status="1" @on-ok="quickInfoData"></QuickInfo>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {getReportInfo, getReportScoreInfo, addReport, reportCommitCheck} from "@/api/report";
import Edit from "@/views/rectifications/Edit";
import categoryEdit from "@/views/task/categoryEdit";
import QuickInfo from "@/components/quickInfo.vue";
import {clone, getBaseDataName} from 'ys-admin'
import {checkInfo, policyInfo} from "@/api/check";
import projects from "@/views/report/Edit/projects.vue"
import {mapGetters} from 'vuex'
import task from "@/views/report/Edit/task.vue";
import photo from "@/views/report/Edit/photo.vue";
import dangerousEngineering from "@/views/task/detail/dangerousEngineering/index.vue";
import problems from "@/views/problems/index.vue";
import risk from "@/views/service/identification/risk";
import category from "@/views/report/Edit/danger.vue";
import Corp from "@/views/projects/detail/corp/index.vue";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    checkId: {type: Number, default: null},
    projectId: {type: Number, default: null},
    serveOrgId: {type: Number, default: null},
  },
  components: {Edit, categoryEdit, QuickInfo, projects, task, problems, risk, category, dangerousEngineering, photo, Corp},
  computed: {
    ...mapGetters(['config']),
    userOrgName() {
      const orgs = (this.userInfo.orgs || []).find(item => item.oType === 3 && this.orgNames.includes(item.iname))
      return orgs?.iname
    },
    stepList() {
      let data = [
        {show: true, ref: 'baseRef', title: '项目信息', components: projects},
        {show: [1, 2].includes(this.check.type) && this.orgNames.includes(this.userOrgName), title: '参建单位', components: Corp},
        {show: [1, 2, 9].includes(this.check.type), ref: 'problemsReg', title: (this.check.type === 9 ? '风险' : '隐患') + '列表', components: (this.check.type === 9 ? risk : problems)},
        {show: [1, 2].includes(this.check.type) && this.orgNames.includes(this.userOrgName), ref: 'reportTask', title: '项目施工进度现状', components: task},
        {show: [1, 2].includes(this.check.type) && this.check.hasDanger === 1, ref: 'dangerRef', title: '超危/危大工程', components: dangerousEngineering},
        {show: [1, 2].includes(this.check.type) && this.orgNames.includes(this.userOrgName) && ['01', '02'].includes(this.projectInfo.category), ref: 'categoryRef', title: '施工现场危大、超危大工程及重大危险源现状反馈', components: category},
        {show: [1, 2].includes(this.check.type) && this.orgNames.includes(this.userOrgName), ref: 'photoRef', title: '现场服务专家与项目工地名称合影', components: photo},
        {show: true, ref: '', title: '报告信息',},
      ];
      return data.filter(item => item.show);
    }
  },
  data() {
    return {
      orgNames: ['上海悠质工程管理咨询有限公司', '上海悠仕网络科技有限公司', '上海悠质工程管理咨询有限公司杭州分公司'],
      loading: true,
      quickInfoShow: false,
      ruleValidate: {
        desc: [
          {required: true, type: 'string', message: '请填写检查结论', trigger: 'blur,change'}
        ],
        suggestion: [
          {required: true, type: 'string', message: '请填写整改建议', trigger: 'blur,change'}
        ],
        score: [
          {required: true, type: 'number', message: '请填写安全评分', trigger: 'blur,change'}
        ],
        result: [
          {required: true, type: 'string', message: '请填写安全等级', trigger: 'change'}
        ],
      },
      checkItemFlag: false,
      amendFlag: false,
      check: {},
      detail: {},
      suggest: [],
      form: {
        id: '',
        checkId: '',
        fileList: [],
        suggestion: '',
        type: '',
        desc: '',
        score: null,
        result: '',
      },
      step: 0,
      submitLoading: false,
      myselfCheck: {},
      baseRef: null,
      submitCheckFlag: false,
      projectAddition: {overview: '', categoryList: [], summary: '', dangerTreeData: [], dangerList: []},
      projectInfo: {},
    }
  },
  async mounted() {
    this.form.checkId = this.checkId;
    this.loading = true;
    await this.getCheckDetail();
    await this.getDetail();
    await this.getCheckScore();
    await this.getPolicyInfo();
    await this.getProjectAddition();
    await this.getProjectInfo();
    if (!this.detail.id && this.detail.type < 3) this.checkItemFlag = true;
    this.loading = false;
  },
  methods: {
    projectOk() {
      this.step++;
      this.getProjectInfo();
      this.getProjectAddition();
    },
    getProjectAddition() {
      this.$get('/check/addition/GetFieldByCheckId', {time: new Date().getTime(), checkId: this.checkId, field: 'projectAddition'}).then((res) => {
        this.projectAddition = res.data || {overview: '', categoryList: [], summary: '', dangerTreeData: []};
        if (!this.projectAddition.categoryList || this.projectAddition.categoryList.length === 0) {
          this.getAddictiveItems();
        }
      })
    },
    handleDangerUploadSuccess(data) {
      this.projectAddition.dangerList = data;
    },
    refresh() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要更新么?更新后已填信息将被清空！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.getAddictiveItems();
        }
      })
    },
    getAddictiveItems() {
      const {categoryList, commentList} = this.check;
      this.projectAddition.categoryList = [];
      categoryList.filter(item => item.enable === 1).forEach(item => {
        this.projectAddition.categoryList.push({
          name: item.name,
          checkCategoryId: item.checkCategoryId,
          type: '',
          tips: '',
          desc: '',
          num: commentList.filter(problem => problem.categoryId === item.id).length
        })
      })
    },
    stepPlus() {
      this.step === 0 ? this.$refs.baseRef[0].save() : this.step++;
    },
    async getCheckDetail() {
      await checkInfo({id: this.checkId}).then(res => {
        this.check = res.data;
        this.form.type = this.check.type;
        this.myselfCheck = this.check.expertList.find(item => item.userId === this.userInfo.id);
      });
      if (this.check.type < 3) {
        await this.$get('/comment/List', {checkId: this.check.id, isTop: false, noArea: true, limit: -1}).then((res) => {
          this.check.commentList = res.data.list
        })
      }
    },
    async getCheckScore() {
      await getReportScoreInfo({checkId: this.form.checkId}).then(res => {
        this.check.categoryList = res.data.categoryList;
      })
    },
    async getPolicyInfo() {
      await policyInfo({id: this.check.policyId}).then(res => {
        this.detail.policy = res.data;
      })
    },
    async getProjectInfo() {
      await this.$get('/projects/GetInfo', {id: this.check.projectId}).then(res => {
        this.projectInfo = res.data;
      }) 
    },
    quickInfoData(data) {
      this.form.suggestion = '根据本次安全检查发现的问题，建议总承包方牵头组织采取以下措施: ';
      data.forEach((item, index) => {
        let desc = item.desc
        if (desc.slice(-1) === '；' || desc.slice(-1) === '。') {
          desc = desc.substr(0, desc.length - 1)
        }
        this.form.suggestion = this.form.suggestion + '\n' + (index + 1) + '、' + desc + ';'
      })
    },
    bLFile(data) {
      this.detail.fileList = data || [];
      this.form.fileList = (data || []).map(item => item.id);
    },
    async getDetail() {
      if (!this.check.reportId) return;
      this.modal.spinShow = true;
      await getReportInfo({id: this.check.reportId}).then((res) => {
        this.detail = res.data;
        Object.keys(this.form).forEach((item) => {
          if (item !== 'fileList') this.form[item] = res.data[item]
        });
        this.form.fileList = (res.data.fileList || []).map(item => item.id);
        this.form.score = this.check.score;
        this.form.result = this.check.result;
        this.modal.spinShow = false;
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    checkDesc(index) {
      if (this.form.desc) {
        this.$Modal.confirm({
          title: '提示',
          content: '检查结论已经存在，是否要覆盖？',
          okText: '覆盖',
          cancelText: '取消',
          onOk: () => {
            this.mode(index)
          }
        })
      } else {
        this.mode(index);
      }
    },
    mode(index) {
      const {policy} = this.detail;
      const {categoryList, commentList} = this.check;
      let comment = '', commentCount = '', riskGradeCount = '';
      this.baseData['风险等级'].forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.value === subItem.riskGrade
        })
        riskGradeCount = riskGradeCount + item.name + '类：' + currentCommentList.length + '项，';
      })
      clone(categoryList).forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.id === subItem.categoryId
        })
        if (currentCommentList.length > 0) {
          commentCount = commentCount + item.name + '类：' + currentCommentList.length + '点位，';
          // 将所有依据拿出来，判断一下是否每一条依据解为是否都有其他标点符号，有则无需手动加直接拼接即可
          const SymbolList = [';', '；', '。', '.', '、', ':', '：', ',', '，']
          const remarksRiskList = currentCommentList.map((i) => i.remarksRisk)
          const isSymbol = remarksRiskList.every(item => {
            return SymbolList.some(SymbolItem => SymbolItem === item.slice(-1))
          })
          // 拼接
          comment = comment + item.iname + '类：' + isSymbol ? remarksRiskList.join('') : remarksRiskList.join('、') + '；'
        }
      })
      switch (index) {
        case 0:
          this.form.desc = '我司根据' + policy.companyName + '安责险风控服务的要求，于' + this.$formatDate(this.check.signTime, 'yyyy年MM月dd日') + '对' + (this.check.projectId > 0 ? this.check.project.iname : policy.enterpriseName) + '进行第 ' + (this.check.numberOfTimes || '') + ' 次安全生产责任险的隐患排查服务工作。本工程目前主要进行' + getBaseDataName('施工阶段', this.check.project.progress, false) + '，根据' + this.check.warehouseName + '，结合工程现阶段的施工情况，对工程现场的' + categoryList.map((item) => {
                return item.name
              }).join('、') + '等几大类的保证项目和一般项目进行了安全隐患的排查服务工作。' + comment +
              '\n本次施工现场安全隐患排查发现的有代表性的安全隐患点共 ' + (commentList || []).length + ' 处，' + (commentCount ? '其中' + commentCount : '') + '根据隐患的风险程度等级，' + riskGradeCount + '本次安全隐患排查所提出的安全隐患不限于上述问题，所排查的是在施工中易于发生安全隐患且有代表性的几个大类，需投保机构根据目前的工程进度和工作内容，有针对性的开展安全检查，对发现的安全隐患及时进行全面整改保证施工生产安全，杜绝安全事故的发生。'
          break;
        case 1:

          this.form.desc = "    我司安全生产风控服务专家团队于" + this.$formatDate(this.check.signTime, 'yyyy年MM月dd日') + "对 进行安全生产责任险事故预防技术服务安全风险隐患排查和安全生产风险预判识别综合分析评估服务工作。" +
              "\n    我司事故技术预防服务专家团队前往该工地提供现场服务，项目管理人员积极配合，对该项目存在的隐患能够及时做出整改。经过事故预防技术服务（安全隐患排查、安全教育）和项目方及时组织整改，该项目极大提高了抗风险能力和应对自然灾害的能力，项目工作人员也对我司事故预防技术服务给与了充分肯定。" +
              "\n    本次检查时，该项目正进行  施工。根据安监总办〔2017〕140号《安全生产责任保险实施办法》、《安全生产责任保险事故预防技术服务规范》AQ 9010-2019、JGJ59—2011《建筑施工安全检查标准》和《危险性较大的分部分项工程安全管理规定》、浙应急法规〔2020〕9号《关于进—步推进我省安全生产贡任保险规范化工作的通知》的要求、结合浙建〔2021〕16 号 《建筑施工安全风险分级管控和隐患排查治理双重预防机制实施细则》的规定，对施工现场现阶段涉及到的易发生事故的重点分项工程及施工部位、施工设备、施工机械进行了针对性的安全隐患排查。" +
              "\n    根据现阶段施工现状特点，我司风控服务专家团队制定了针对性的事故技术预防服务方案，本次现场检查的重点是：" +
              "\n一、项目风险综合总体评价：" +
              "\n     " +
              "\n二、项目风险分项评价：" +
              "\n     " +
              "\n 三、项目风险及后续施工安全管理建议：" +
              "根据本次安全隐患排查所涉及到的问题和隐患分布情况结合对工程全面安全生产风险预判识别分析评估结果，建议施工方组织采取以下措施：\n" +
              "1.加强教育培训，提高全员安全意识。\n" +
              "人的不安全行为是建筑施工安全风险的三大因素之一，高处坠落、触电等建筑施工安全事故都与人的行为有关。重点抓好二个环节的安全生产教育：一是抓好建筑施工特殊工种的安全技能教育，二是加强岗前教育培训和安全技术交底。加强安全管理，加强项目各分项工程新进班组、人员的进场安全教育培训和岗前培训工作，加强各工序施工前的安全技术交底工作；定期对脚手架、支模架等分项工程进行验收，加强对特种作业人员如起重吊装工、电工、脚手架操作工等工种持证操作的管理。\n" +
              "2.构建建筑安全防护措施，预防事故发生。\n" +
              "由于人容易受到心理、生理等条件的局限，提高安全生产意识并不能完成杜绝建筑施工安全事故，建筑安全防护措施也是预防事故的重要手段。重点抓好“三宝”、“四口”、“五临边”、移动操作平台的安全防护，防范高处坠落事故发生。\n" +
              "3.制定和完善应急预案，定期组织应急演练，最大限度防止事故扩大。\n" +
              "4.严格执行事故隐患排查治理制度，严格落实“查、整、消”闭环机制。做好施工现场围挡的设置和维护工作，做好门岗实名登记制度，杜绝非施工人员的进出，做好进场车辆的登记工作，保证施工现场的施工安全和规范管理。加强施工区、生活区和办公区文明施工和消防管理的监督工作，根据火灾类型配置不同类型的灭火设备设施，保证施工现场、办公区和宿舍区防火、防触电的安全措施到位。\n" +
              "5.施工用电在施工过程中因作业人员麻痹大意不遵守操作规程而造成的安全事故频繁发生。须注意施工用电的规范管理和对施工人员进行教育培训，加强现场的监督检查，非电工人员严禁私拉乱接电线，施工现场配电系统应采用三级配电、二级漏电保护系统，做好接地与接零保护系统，避免施工中不慎发生触电事故。\n" +
              "6.脚手架施工安全风险防范要点:\n" +
              "1）脚手架工程必须按照规定编制、审核专项施工方案，超过一定规模的要组织专家论证。\n" +
              "2）脚手架搭设、拆除单位必须具有相应的资质和安全生产许可证，严禁无资质从事脚手架搭设、拆除作业。\n" +
              "3）脚手架搭设、拆除人员必须取得建筑施工特种作业人员操作资格证书。\n" +
              "4）脚手架搭设、拆除前，应按规定向现场管理人员进行方案交底，向作业人员进行安全技术交底。\n" +
              "5）脚手架材料进场使用前，必须按规定进行验收，未经验收或验收不合格的严禁使用。\n" +
              "6）脚手架搭设、拆除要严格按照专项施工方案组织实施，相关管理人员必须在现场进行监督，发现不按照专项施工方案施工的，应当要求立即整改。\n" +
              "7）脚手架外侧以及悬挑式脚手架、附着升降脚手架底层应当封闭严密。\n" +
              "8）脚手架必须按专项施工方案设置剪刀撑和连墙件。落地式脚手架搭设场地必须平整坚实。严禁在脚手架上超载堆放材料，严禁将模板支架、缆风绳、泵送混凝土和砂浆的输送管等固定在架体上。\n" +
              "9）脚手架搭设必须分阶段组织验收，验收合格的，方可投入使用。\n" +
              "10）脚手架拆除必须由上而下逐层进行，严禁上下同时作业。连墙件应当随脚手架逐层拆除，严禁先将连墙件整层或数层拆除后再拆脚手架。\n" +
              "7.本工程基坑土方开挖工程深度大于3m，属于危险性较大的分部分项工程。项目周边既有建（构）筑物较密集且离基坑边沿较近，且该项目地势低洼，安全预判识别风险极高。基坑土方开挖施工必须严格按照方案和相关规范进行。目前基坑基础工程开始地下室底板钢筋绑扎施工，正处在雷暴大于和台风季节，必须实时关注基坑周边既有建（构）筑物的监测，预防已有裂缝的进一步发展；同时注意基坑积水，保证积水的及时抽排，保障基坑降水和基坑的稳定性，对基础筏板施工做到完成后及时浇筑，尽快回填，保证项目地基的稳定和周边既有建（构）筑物的安全。\n" +
              "8.本工程模板支架属于高支模工程，安全风险预判识别风险系数较高，需要加强支模架安拆人员的持证上岗、安全交底、安全教育培训工作。按规范要求进行专家论证并严格按照论证后方案方进行模板支架搭设施工，模板支架拆除必须符合相关规范要求，避免发生坍塌事故。\n" +
              "9.施工升降机类的安全隐患预判风险识别为较大，因而在后期搭设拆除使用过程严格按照方案进行，施工升降机各停平层防护门必须按规范设置并关闭严密，加强起重限位器、防坠安全器、限位装置、附墙装置、基础和导轨架等重要部位检查监督管理工作，防止施工升降机的起重伤害事故发生。\n" +
              "10.起重机械使用安全风险防范要点：\n" +
              "起重机械使用单位必须建立机械设备管理制度，并配备专职设备管理人员。\n" +
              "起重机械安装验收合格后应当办理使用登记，在机械设备活动范围内设置明显的安全警示标志。\n" +
              "起重机械司机、司索信号工必须取得建筑施工特种作业人员操作资格证书。　　\n" +
              "起重机械使用前，应当向作业人员进行安全技术交底。\n" +
              "起重机械操作人员必须严格遵守起重机械安全操作规程和标准规范要求，严禁违章指挥、违规作业。\n" +
              "遇大风、大雾、大雨、大雪等恶劣天气，不得使用起重机械。\n" +
              "起重机械应当按规定进行维修、维护和保养，设备管理人员应当按规定对机械设备进行检查，发现隐患及时整改。\n" +
              "起重机械的安全装置、连接螺栓必须齐全有效，结构件不得开焊和开裂，连接件不得严重磨损和塑性变形，零部件不得达到报废标准。\n" +
              "两台以上塔式起重机在同一现场交叉作业时，应当制定塔式起重机防碰撞措施。任意两台塔式起重机之间的最小架设距离应符合规范要求。\n" +
              "塔式起重机使用时，起重臂和吊物下方严禁有人员停留。物件吊运时，严禁从人员上方通过。\n" +
              "11.吊篮安装必须严格按照规范要求，安装后经检测、验收合格后方可投入使用。使用过程中落实日检管理，有问题及时进行整改。加强作业人员班前安全教育与交底，遵守操作规程，严禁违章作业、违章指挥，避免发生高处坠落及坍塌等事故。\n" +
              "11.夏时节高温少雨，酷热难耐，施工现场环境温度高，加之雷雨、大风等灾害性天气频繁，给工程施工安全带来很多不利因素，是施工事故的多发季节！施工作业安全面临巨大挑战！为了切实加强夏季安全生产，防范各类安全隐患发生，保障广大工程人员的生命财产安全，必须提前做好防范措施，下面从防暑降温、用电安全、个人防护用品穿戴、防台防汛四个方面普及夏季施工安全防范措施：\n" +
              "防暑降温：夏季天气比较炎热，同时雷雨天气多，生产现场存在高温、潮湿环境，极大地增加了中暑的风险。\n" +
              "   [防范措施]：\n" +
              "作业现场应设置休息棚，配备防暑降温药品，并供应茶水、清凉含盐饮料、绿豆汤等。\n" +
              "高温作业时，应多喝水，每天喝1.5至2升水。出汗较多时可适当补充一些盐水，弥补人体因出汗而失去的盐分。\n" +
              "高温作业时，若出现头晕、恶心、胸闷、心悸、乏力等中暑先兆时，应立即到阴凉处休息，并服用防暑降温药品、清凉饮料等。\n" +
              "日最高气温达到35℃时，应根据生产工作情况，采取换班轮休等方法，缩短员工连续作业时间；12时到15时应尽量避免露天作业；日最高气温达到40℃时，当日应停止工作。\n" +
              "在露天作业中的固定场所，应搭设遮阳棚。\n" +
              "现场的气瓶等易燃易爆物品应有防曝晒措施。\n" +
              "职工宿舍宜统一装配空调降温，应保持通风干燥，定期清扫、消毒，保持室内整洁、卫生。\n" +
              "用电安全：在雨季，极容易发生漏电、触电事故。由于雨水浸湿的导线及各种电气设备其绝缘性受到影响，加之高温，容易导致老化、破损，极易使电气设备外壳带电，潮湿的物体、空气也容易导电，若有一处漏电就可能造成触电事故。\n" +
              "     [防范措施]：\n" +
              "施工现场临时用电设备和线路的安装、巡检、维修或拆除，应由电工完成。电工应经考核合格后，持证上岗工作。\n" +
              "使用电气设备前，作业人员必须按规定佩戴相应的劳动保护用品。\n" +
              "定期对施工现场临时用电进行检查，发现安全隐患及时处理。\n" +
              "加强电气设备线路检查，及时更换破损的电线和电箱，防止漏电、触电。\n" +
              "各种施工机具和施工设施应做好保护零线连接。\n" +
              "电气人员做好日常巡检和交接班，雷雨天气要加强巡检，每班要对现场临时电源线检查一次，固定电源线路做好日常检查，并做好记录。\n" +
              "个体防护用品穿戴：为了保护施工人员在施工过程中的人身安全，施工人员必须穿戴绝缘手套、绝缘鞋、安全带、安全帽等个体防护用品，由于气候原因，会让人觉得更热，导致部分工人不按要求佩戴个体防护用品。\n" +
              "    [防范措施]：\n" +
              "1）定期开展日常安全专项培训，提升员工个体防护用品穿戴规范安全意识。\n" +
              "2）加强季节性、节假日安全检查，及时纠正个体防护用品穿戴不规范行为。\n" +
              "防台防汛：夏至后，多雷暴雨、台风是这季节的气候特点，东部沿海地区是台风的频繁光顾地区，高大施工机械是雷击最有可能光顾的设施，暴雨是所有室外作业的祸首，因此防汛、防雷、防台风措施的落实迫在眉睫。\n" +
              "    [防范措施]：\n" +
              "制定防台防汛应急预案，建立健全应急救援组织及应急抢险队伍；\n" +
              "积极开展防台防汛应急演练，签订好应急避难场所协议；\n" +
              "作业现场应提前做好防汛准备，备足防汛应急物资和器材（抽水设备）；作业人员应配备雨衣、雨鞋等防护用品。\n" +
              "并根据施工现场具体情况，按要求在脚手架等高度较高的突出结构物上安装避雷装置。\n" +
              "现场的大型临时设备周边设置排水沟，严防雨水冲入设备内。\n" +
              "在雷暴季节，现场脚手板、斜道板（防滑条）、作业平台应设置防滑措施，防止雨天工人滑倒。\n" +
              "大风（台风）来临前，应对施工现场的临时用房、围墙、脚手架、塔吊、施工电梯等防风安全措施进行检查、加固。\n" +
              "现场所有松散的材料都要绑扎并锚固或者转移到安全区域。\n" +
              "大风天气施工时，应定期对现场中大型设备的防雷接地、现场和生活区临时用电进行检查；不使用的电器设备应切断电源。\n" +
              "六级以上大风天气，不得进行露天攀登与悬空高处作业。\n" +
              "六级及以上大风天气，应停止露天起重吊装作业。\n" +
              "施工升降机应停止运行，将梯笼降到底层并切断电源。\n" +
              "大风（台风）天气过后，重新施工前，应先检查下现场设备设施，特别是特种施工设备、重要设施，检查完好才能使用。\n" +
              "7悬空作业安全\n" +
              "(1)悬空作业处应有牢固的立足之处,并必须视具体情况,配置防护栏杆、栏网或其他安全设施。\n" +
              "(2)悬空作业所使用的索具、脚手架、吊蓝、平台等设备,需经过技术验证或鉴定才能使用。\n" +
              "(3)钢结构的吊装,构件应尽可能在地面组装,并搭设临时高空设施,以利于高空固定,点焊、螺栓连接等工序。拆\n" +
              "卸时的安全措施,也应一并考虑。高空吊装大型构件前,也应搭设悬空作业中所需的安全设施。\n" +
              "(4)进行预应力张拉时,应搭设站立操作人员和设置张拉设备用的牢固可靠的脚手架或操作平台。预应力张拉\n" +
              "区域标示明显的安全标志,禁止非操作人员进入。\n" +
              "(5)悬空作业人员,必须佩戴好安全帽、安全带等。\n" +
              "8结构焊接安全\n" +
              "(1)电焊机外壳,必须接地良好,其电源的装拆应由电工进行。\n" +
              "(2)电焊机设备单独开关,开关应放在防雨的匣箱内,拉合匣时,应戴手套,侧向操作。\n" +
              "(3)焊钳与把线必须绝缘良好,连接牢固,更换焊条应戴手套。潮湿地点,应站在绝缘胶板或木板上。\n" +
              "(4)严禁在带压力的容器或管道上施焊,焊接带电的设备必须先切断电源，\n" +
              "(5)贮存过易燃易爆、有毒物品的容器或管道,必须先清楚干净,并将排气孔口打开。\n" +
              "(6)焊接预热工件时,应有石棉匝布或挡板等隔热措施。\n" +
              "(7)把线、地线禁止与刚丝绳接触,更不得用钢丝绳或机电设备代替零线。所有地线接头,必须连接牢固。\n" +
              "(8)更换场地移动把线时,应切断电源,并不得手持把线爬高攀登。\n" +
              "(9)雷雨时,不得进行露天焊接作业。\n" +
              "防止爆炸\n" +
              "防止爆炸主要发生在气割过程中,由于要用到乙炔瓶,所以储存和使用乙炔瓶注意以下几方面问题:\n" +
              "(1)乙炔瓶储库的设计和建造,应符合建筑设计防火规范中乙炔站设计规范的有关规定。\n" +
              "(2)储存间应有专人管理,在醒目的地方应设置标志。\n" +
              "(3)严禁与氯气瓶、氧气瓶及易燃物品同间储存。\n" +
              "(4)储存间与明火或散发火花地点的距离,不得小于15m,且不应设地下室或半地下室。\n" +
              "(5)储存间应有良好的通风、降温等设施,要避免阳光直射,要保证运输畅通,在其附近应设置有消防栓和干粉。\n" +
              "(6)乙炔瓶储存时,一般要保持竖立位置,并应有防止倾倒的措施。\n" +
              "(7)使用乙炔瓶的现场,储存量不得超过5瓶,超过5瓶但不得超过20瓶,应在现场或车间内用非燃烧体或难燃体隔\n" +
              "墙分库;储存量不超过40瓶的乙炔库房,可与耐火等级不低于二级的生产厂房毗郐建造。\n" +
              "12.对安全生产责任险第三方事故技术预防服务专家团队提出的安全隐患及时进行整改。"
          break;
      }
    },
    submit(type) {
      this.$refs.form[0].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          const res = await addReport(this.form);
          await this.$post('/check/addition/AddOrEdit', {data: this.projectAddition, field: 'projectAddition', checkId: this.checkId})
          if (res.code === 200) {
            this.$Notice.success({title: '提示', desc: '报告保存成功！'});
            this.check.reportId = res.data.id;
            this.submitLoading = false;
            if (type) this.checkProblems()
            this.$emit('on-ok', res);
            this.close();
          } else {
            this.$Notice.error({
              title: "操作失败！",
              desc: '请检查表单是否填项正确！'
            });
          }
          this.submitLoading = false;
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
        }
      }).catch(() => {
      })
    },
    commit() {
      const {type, checkStatus, isInsCheckReport, isCheckReport, insuranceOrgName} = this.detail;
      console.log(type, checkStatus, isInsCheckReport, isCheckReport, insuranceOrgName)
      let msg = '';
      if (isCheckReport) {
        if (checkStatus === 10 || checkStatus === 30 || checkStatus === 50) {
          /*提交给专家*/
          msg = "<div style='color:#ff0900'>本次将提交给专家组组长审核！</div>";
        }
      } else if (isInsCheckReport) {
        /*提交给保险公司审核*/
        msg = `<div style='color:#de4e00'>本次将提交给保险公司(${insuranceOrgName})审核！</div>`;
      } else {
        msg = `<div style='color:#007911'>本次将提交给保险公司(${insuranceOrgName})，无需审核！</div>`;
      }
      this.$Modal.confirm({
        title: '提示',
        content: (type >= 2 ? '确定要提交报告么？' : '请确认项目阶段、五牌一图、单体建筑物是否更新！提交后将无法录入风险和生成整改单！') + msg,
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          this.submitReport();
        }
      });
    },
    async submitReport() {
      reportCommitCheck({checkId: this.check.id, type: this.check.type}).then((res) => {
        if (res.code === 200) {
          this.$emit('on-ok', res);
          this.$Notice.success({
            title: '操作成功！'
          });
        }
        this.$Modal.remove()
      });
    },
    checkProblems() {
      const {type, commentList} = this.check
      if (type > 3) {
        this.commit();
      } else {
        this.$Spin.show();
        let errorList = [], content = '';
        (commentList || []).forEach(item => {
          item.fileList = item.fileList || [];
          const errorItem = [];
          if (item.fileList.length === 0) errorItem.push("附件")
          if (!item.riskGrade) errorItem.push("风险等级")
          if (!item.accidentType) errorItem.push("可能导致的事故类型")
          if (!item.remarksText) errorItem.push("风险描述")
          if (!item.remarksRisk) errorItem.push("风险依据")
          if (!item.remarksSuggest) errorItem.push("整改建议")
          if (errorItem.length > 0) errorList.push({itemName: item.itemName, errorItem})
        })
        if (errorList.length > 0) {
          errorList.forEach((item, index) => {
            content = content + (index + 1) + '、【' + item.itemName + '】的 <span style="color:#ff0900">' + item.errorItem.join('、') + '</span> 等未填写完整；<br/>'
          })
          this.$Modal.remove();
          window.setTimeout(() => {
            this.$Modal.confirm({
              okText: '前往修改',
              width: 500,
              cancelText: '关闭',
              title: '风险必填项检查失败！',
              content: content + '请前往任务详情查看风险信息，再仔细检查并完善信息！',
              onOk: () => {
                this.detailFlag = true;
              }
            });
          }, 300);
        } else {
          this.commit();
        }
        this.$Spin.hide();
      }
    },
  }
}
</script>

<style lang="less" scoped>
.suggestion {

  li {
    padding: 7px 5px;
    width: 768px;
    white-space: break-spaces;
    border-bottom: 1px solid #ececec;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }
}

.editForm {
  height: calc(100vh - 290px);
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
}

</style>
