<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <Alert type="warning">未勾选的检查项将不会出现在报告和整改单中，也无法对其新增风险。已录入风险的检查项无法去除勾选！</Alert>
      <FormItem label="检查标准" prop="warehouseId " v-if="form.commentCount<=0">
        <Select v-model="form.warehouseId" @on-change="getStandardInfo" placeholder="检查标准">
          <Option v-for="(item) in standardList" :value="item.id" :key="item.id"> {{ item.iname }}</Option>
        </Select>
      </FormItem>
      <FormItem label="检查项目" prop="category" style="width: 100%">
        <CheckboxGroup v-model="form.category">
          <Checkbox v-for="(item) in categoryList" class="checkbox-border-item" border :label="item.id" :disabled="item.commentCount>0" :key="item.id"> {{ (item.name || item.iname) + '(' + (item.commentCount || 0) + ')' }}</Checkbox>
        </CheckboxGroup>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">保存</Button>
      <Button @click="close">取消</Button>
    </div>

  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {checkCategoryList, checkInfo} from "@/api/check";
import {getStandardInfo, getStandardData} from "@/api/common";
import {all} from 'ys-admin'

export default {
  name: 'categoryEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        category: [
          {required: true, type: 'array', message: '请至少选项一个检查项', trigger: 'change'}
        ],
      },
      categoryList: [],
      standardList: [],
      warehouseId: '',
      form: {
        warehouseId: '',
        id: '',
        checkId: '',
        commentCount: 0,
        category: [],
        categoryList: [],
      },
    }
  },
  mounted() {
    this.form.id = this.mid;
    this.form.checkId = this.mid;
    all([
      getStandardData({limit: -1, status: 1}),
      checkInfo({id: this.mid})
    ]).then((res) => {
      this.form.id = this.mid;
      this.standardList = res[0].data.list || [];
      this.form.warehouseId = res[1].data.warehouseId;
      this.warehouseId = res[1].data.warehouseId;
      // this.form.category = res[1].data.categoryList.filter(item => item.enable === 1).map(item => item.id);
    })
    this.getCategoryList();
  },
  methods: {
    async getStandardInfo() {
      if (this.warehouseId === this.form.warehouseId) {
        this.getCategoryList();
      } else {
        await getStandardInfo({id: this.form.warehouseId, random: Math.ceil(Math.random() * 100)}).then(res => {
          this.form.category=[];
          this.categoryList = res.data.categoryList || [];
          // 默认编辑时当切换后再自动全选，或者新增直接自动全选
          // if (event || !this.mid) {
          //   this.form.category = this.categoryList.map((item) => item.id);
          // }
        })
      }
    },
    save() {
      this.form.categoryList = this.categoryList.map(item => {
        item.enable = 0;
        item.categoryId = item.id;
        if (this.form.category.indexOf(item.id) >= 0) item.enable = 1;
        return item;
      });
      if (this.warehouseId !== this.form.warehouseId && this.form.commentCount <= 0) {
        this.ok('/check/EditWarehouse')
      } else {
        this.ok('/check/category/Edit')
      }
    },
    getCategoryList() {
      checkCategoryList({checkId: this.mid}).then((res) => {
        this.categoryList = res.data || [];
        this.form.category = this.categoryList.filter(item => item.enable === 1).map(item => item.id);
        this.form.commentCount = this.categoryList.filter(item => item.enable === 1).reduce((per, next) => per + (next.commentCount || 0), 0);
      })
    },
  }
}
</script>

<style lang="less" scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}
</style>
