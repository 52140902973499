<script>
import edit from '@/mixins/edit';
import {clone, del} from "ys-admin";

export default {
  name: 'danger',
  mixins: [edit],
  components: {},
  props: {
    checkDetail: {type: Object, default: () => ({})},
  },
  data() {
    return {
      dangerList: [
        {category: '01', name: '安全管理及文明施工（现场围挡、道路、办公区与生活区、材料摆放、警示标志设置、消防管理等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '基坑工程（土方开挖、坑边荷载、降排水、支护结构、基坑监测、临边防护及通道等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '脚手架（立杆基础、剪刀撑、连墙件、脚手板与防护、悬挑钢梁、钢拉杆、爬架等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '吊篮（安全装置、悬挂机构、钢丝绳、升降作业）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '模板支架（基础承载力、支架构造、支架稳定、高支模拉结点设置等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '高处作业（临边、洞口、悬挑卸料平台、悬空作业、操作平台）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '施工用电（外电防护、配电线路、配电箱与开关箱、总配房等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '施工升降机、物料提升机（安全装置、防护设施、附墙架、持证上岗）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '01', name: '塔式起重机与起重吊装（基础、附墙、钢丝绳、信号指挥）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '安全管理及文明施工（现场围挡、道路、办公区与生活区、材料摆放、警示标志设置、消防管理等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '脚手架与操作平台（立杆基础、剪刀撑、连墙件、脚手板与防护、悬挑钢梁、钢拉杆、爬架等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '模板支架（基础承载力、支架构造、支架稳定、高支模拉结点设置等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '高处作业（临边、洞口、悬挑卸料平台、悬空作业、操作平台）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '施工用电（外电防护、配电线路、配电箱与开关箱、总配房等）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '起重吊装', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '围堰（围堰构造、安装、监测、拆除）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '沉井（构造、下沉与接高、浮运）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '栈桥、猫道', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '悬臂式挂篮施工', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '矿山法隧道（初期支护、监测、隧道开挖）', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '盾构施工', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
        {category: '02', name: '地下暗挖与顶管作业', resourceId: this.checkDetail.id, resourceType: 2, fileList: []},
      ],
      projectAddition: {},
      category: '',
    };
  },
  computed: {
    token: function () {
      return sessionStorage.getItem('token')
    },
  },
  async mounted() {
    if (this.checkDetail.projectId) {
      await this.$get('/projects/GetInfo', {id: this.checkDetail.projectId}).then(res => {
        if (res.code === 200) {
          this.category = res.data.category;
          this.getFiles();
        }
      })
    }
  },
  methods: {
    async getFiles() {
      await this.$get('/check/addition/GetFieldByCheckId', {time: new Date().getTime(), checkId: this.checkDetail.id, field: 'projectAddition'}).then((res) => {
        this.projectAddition = res.data;
        this.$set(this, 'projectAddition', res.data)
        const dangerListOrigin = (this.projectAddition.dangerList || []).filter(item => item.category === this.category)
        this.$set(this.projectAddition, 'dangerList', dangerListOrigin.length > 0 ? dangerListOrigin : this.dangerList.filter(item => item.category === this.category))

      })
      await this.save()
    },
    async bLFile(data, index) {
      console.log(data)
      const oldFileList=clone(this.projectAddition.dangerList[index].fileList)
      this.projectAddition.dangerList[index].fileList = clone(data).map((item,idx) => {
        return {url: !!item.response?item.response.data.url:oldFileList[idx].url}
      })
      this.projectAddition.dangerList[index].files = clone(data).map(({...item}) => {
        return !!item.response?item.response.data:item
      })
      // this.projectAddition.dangerList = this.defaultFile;
      await this.save()
    },

    async save() {
      await this.$post('/check/addition/AddOrEdit', {data: this.projectAddition, field: 'projectAddition', checkId: this.checkDetail.id}).then(res=>{
        if(res.code===200){
          this.$emit('dangerUploadSuccess',this.projectAddition.dangerList)
        }
      })
    }
  }
};
</script>

<template>
  <div class="taskBox">
    <ul class="taskBoxItem">
      <li v-for="(ele, index) in projectAddition.dangerList||[]" :key="index">
        <h1 class="h1Title" v-if="ele.name">{{ ele.name }}</h1>
        <ysUpload
            @on-success="bLFile($event, index)"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList="{...ele}.files"
            :format="['jpg','jpeg','png','gif']"
            action="/web/resource/UploadFile"></ysUpload>
      </li>
    </ul>
  </div>
</template>


<style scoped lang="less">
.taskBox {
  background-color: var(--el-bg-color);
  height: calc(100vh - 300px);
  overflow-y: auto;

  :deep(.el-tabs) {
    height: 100%;
    overflow-y: auto;
  }

  :deep(.el-tabs__header) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    flex-shrink: 0 !important;
  }

  :deep(.el-tabs__content) {
    height: 100%;
    overflow-y: auto;
  }

  :deep(.el-tabs__content) {
    height: 100%;
    overflow-y: auto;
  }

  .taskBoxItem {
    height: 100%;
    overflow-y: auto;
  }
}
</style>
